<template>
    <div class="col-lg-8 entries">
      <div v-if="isLoading" class="container position-relative p-0 mb-5">
        <div class="carousel-inner animated-background position-relative rounded">
          <div class="carousel-item btn-divide-left active">
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <h2 class="fw-bold text-center">Our Events</h2>
        <div v-for="item in data" :key="item.id" class="col-sm-4">
          <div class="entry rounded-bottom">
            <div class="entry-img rounded-top">
              <img :src="resource + item.photo" alt="" class="img-fluid" />
            </div>
  
            <h2 class="entry-title">
              <router-link
                style="font-size: 15px"
                :to="{
                  name: 'event-detail',
                  params: {
                    id: $CryptoJS.AES.encrypt(
                      `${item.id}`,
                      'Secret Passphrase'
                    ).toString(),
                  },
                }"
              >
                {{ item.name }}
              </router-link>
            </h2>
  
            <div class="entry-content">
              <div class="read-more mt-4">
                <router-link
                  :to="{
                    name: 'event-detail',
                    params: {
                      id: $CryptoJS.AES.encrypt(
                        `${item.id}`,
                        'Secret Passphrase'
                      ).toString(),
                    },
                  }"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-pagination overflow-scroll">
        <ul v-if="totalPage > 1" class="justify-content-center">
          <li class="hover-none" v-for="(i,index) in totalPage" :key="index">
            <button 
              :disabled="isFetch"
              type="button"
              :class="`btn ${
                i == current_page ? 'btn-primary' : 'btn-outline-primary'
              }`"
              @click="goToPage(i)"
            >
              {{ i }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { getEvents } from "@/networks/EventPage/event.service";
  import { resource } from "@/networks/domain";
  export default {
    name: "EventView",
    data() {
      return {
        resource : resource,
        data : [],
        per_page: 6,
        current_page:1,
        totalPage: 0,
        isFetch: false,
        isLoading: true,
      };
    },
    watch: {
      async current_page(after, before) {
        if (after != before) {
          this.isFetch = true;
          this.isLoading = true;
          await this.getData().then(() => {
            this.$nextTick(() => {
              $("html, body").animate(
                {
                  scrollTop: $("#main").offset().top - 100,
                },
                20
              );
            });
          });
        }
      },
    },
    methods: {
      goToPage(i) {
        this.current_page = i;
      },
      async getData() {
        await axios
          .get(`${getEvents}?per_page=${this.per_page}&page=${this.current_page}`)
          .then((res) => {
            if (res.status == 200) {
              this.data = res.data.data.events.data;
              this.totalPage = res.data.data.events.last_page;
              this.isFetch = false;
              this.isLoading = false;
            }
          })
          .then(() => {
            $("html, body").animate(
              {
                scrollTop: $("#main").offset().top - 100,
              },
              20
            );
          })
          .catch((err) => {
            console.log(err);
            // this.$swal(
            //   {
            //     icon: 'warning',
            //     title: err,
            //   }
            // )
          });
      },
      strippedHtml(data) {
        let regex = /(<([^>]+)>)/gi;
        return data.replace(regex, "");
      },
      shortDescription(str, lengthRequired = 100) {
        let str_data = this.strippedHtml(str);
        return str_data.substring(0, lengthRequired);
      },
    },
    async created() {
      await this.getData();
    },
  };
  </script>
  
  <style scoped>
  .hover-none:hover {
    background-color: transparent !important;
  }
  </style>
  