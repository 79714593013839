<template>
    <div class="col-lg-8 entries pb-4">
        <div v-if="isLoading" class="container position-relative p-0 mb-5">
            <div class="carousel-inner animated-background position-relative rounded">
                <div class="carousel-item btn-divide-left active">
                <div class="carousel-caption d-none d-md-block"></div>
                </div>
            </div>
        </div>
        <div v-else class="rounded shadow">
            <div v-if="item.photo" class="w-100">
                <img :src="resource + item.photo" alt="" class="w-100 rounded-top">
            </div>
            <h2 class="fw-bold entry-title p-4">{{ item.name }}</h2>
            <div class="entry-content p-4">
                <div class="text-justify" v-html="item.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { resource } from '@/networks/domain';
import { getEventDetail } from '@/networks/EventPage/event.service';

export default {
    name : 'EventDetailView',
    data(){
        return {
            resource : resource,
            item : [],
            photos : [],
            isLoading : true,
        }
    },
    // watch: {
    //     async $route(after, before){
    //         if(this.$CryptoJS.AES.decrypt(`${after.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8) != this.$CryptoJS.AES.decrypt(`${before.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)){
    //             this.isLoading = true;
    //             await this.getData();
    //         }
    //     }
    // },
    methods : {
        async getData(){
            await axios.get(`${getEventDetail}?id=${this.$CryptoJS.AES.decrypt(`${this.$route.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)}`)
            .then((res)=>{
                if(res.status == 200){
                    this.item = res.data.data;
                }
            })
            .then(()=>{
                $('html, body').animate({
                    scrollTop: $("#main").offset().top - 100
                }, 20);
            })
            .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
            });
            this.isLoading = false;
        }
    },
    async created(){
        await this.getData();
    },
}
</script>

<style>

</style>