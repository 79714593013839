<template>
    <div class="row my-5">
        <div class="col-lg-12 col-md-12">
            <h2 class="fw-bolder">Membership Profile</h2>
        </div>
    </div>
    <div v-if="isLoading" class="row my-5">
        <div class="col-12 rounded-4 shadow p-3">
            <span class="placeholder col-7"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
            <span class="placeholder col-8"></span>
            <span class="placeholder col-6"></span>
        </div> 
    </div>
    <template v-else>
        <div class="row my-5 px-2">
            <div class="col-lg-12">
                <div class="row shadow rounded-4 p-3">
                    <div class="col-lg-11 col-md-12">
                        <p v-if="membership_info.is_approve != 0" class="my-1">You become a member of CBC-Cambodia on: {{ new Date(membership_info.become_member_date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric'}) }}</p>
                        <p class="my-1">Membership Type : <span class="fw-bold text-muted">{{ membership_info.member_category_name }}</span></p>
                        <p class="fw-bold text-muted my-1">Annual Fee : 150.00 USD or 600,000.00 KHR</p>
                        <p v-if="membership_info.is_approve != 0" class="my-1">Membership Expiration Date: <span class="fw-bold text-muted">{{ new Date(membership_info.next_fee_date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric'}) }}</span></p>
                    </div>
                    <div class="col-lg-1 col-md-12 m-lg-auto mt-2 text-center">
                        <img src="@/assets/member.png" class="img-fluid img-thumbnail rounded-circle" alt="">
                    </div>
                </div>
            </div>
            <div class="col-12 mt-5 p-4 shadow rounded-4">
                <div class="row mb-4">
                    <div class="col-7">
                        <h2 class="fw-bolder m-0">
                            Brief Description
                        </h2>
                        <i><small>A brief description of your company</small></i>
                    </div>
                    <div class="col text-end">
                        <button @click="edit('about')" class="btn btn-outline-primary"><i class="bi bi-pen"></i></button>
                    </div>
                </div>
                <p>{{ membership_info.about ? membership_info.about : 'No Description'}}</p>
            </div>
            <div class="col-12 mt-5 p-4 shadow rounded-4">
                <div class="row mb-4">
                    <div class="col-7">
                        <h2 class="fw-bolder m-0">
                            Looking For
                        </h2>
                        <i> <small>Brief interest of what your are looking for</small> </i>
                    </div>
                    <div class="col text-end">
                        <button @click="edit('looking')" class="btn btn-outline-primary"><i class="bi bi-pen"></i></button>
                    </div>
                </div>
                <p>{{ membership_info.looking_for ? membership_info.looking_for : 'No Description' }}</p>
            </div>
            <div class="col-12 mt-5 p-4 shadow rounded-4">
                <div class="row mb-4">
                    <div class="col-7">
                        <h2 class="fw-bolder">
                            Company Infomation
                        </h2>
                    </div>
                    <div class="col text-end">
                        <button @click="edit('companyInfo')" class="btn btn-outline-primary"><i class="bi bi-pen"></i></button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-striped fs-6">
                        <tbody>
                            <tr>
                                <td>ID</td>
                                <td>:</td>
                                <td>{{ membership_info.code }}</td>
                            </tr>
                            <tr>
                                <td>Country</td>
                                <td>:</td>
                                <td>{{ membership_info.country }}</td>
                            </tr>
                            <tr>
                                <td>City / State</td>
                                <td>:</td>
                                <td>{{ membership_info.city }}</td>
                            </tr>
                            <tr>
                                <td>Zip Code</td>
                                <td>:</td>
                                <td>{{ membership_info.zip_code }}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>:</td>
                                <td>{{ membership_info.name }}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>:</td>
                                <td>{{ membership_info.phone }}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>:</td>
                                <td>{{ membership_info.email }}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>:</td>
                                <td>{{ membership_info.address }}</td>
                            </tr>
                            <tr>
                                <td>Website</td>
                                <td>:</td>
                                <td>{{ membership_info.website }}</td>
                            </tr>
                            <tr>
                                <td>Youtube</td>
                                <td>:</td>
                                <td>{{ membership_info.youtube }}</td>
                            </tr>
                            <tr>
                                <td>Linkedin</td>
                                <td>:</td>
                                <td>{{ membership_info.linkedin }}</td>
                            </tr>
                            <tr>
                                <td>Instragram</td>
                                <td>:</td>
                                <td>{{ membership_info.instragram }}</td>
                            </tr>
                            <tr>
                                <td>Facebook</td>
                                <td>:</td>
                                <td>{{ membership_info.facebook }}</td>
                            </tr>
                            <tr>
                                <td>Twitter</td>
                                <td>:</td>
                                <td>{{ membership_info.twitter }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- contact info -->
            <div class="col-12 mt-5 p-4 shadow rounded-4">
                <h2 class="fw-bolder mb-4">
                    Contact Person
                    <button @click="edit('contactPerson')" class="btn btn-outline-primary"><i class="bi bi-pen"></i></button>
                </h2>
                <div class="table-responsive">
                    <table class="table table-hover table-striped fs-6">
                        <tbody>
                            <tr>
                                <td>Key Person Name</td>
                                <td>:</td>
                                <td>{{ membership_info.key_person_name }}</td>
                            </tr>
                            <tr>
                                <td>Key Person Position</td>
                                <td>:</td>
                                <td>{{ membership_info.key_person_position }}</td>
                            </tr>
                            <tr>
                                <td>Key Person Phone</td>
                                <td>:</td>
                                <td>{{ membership_info.key_person_phone }}</td>
                            </tr>
                            <tr>
                                <td>Key Person Email</td>
                                <td>:</td>
                                <td>{{ membership_info.key_person_email }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- datatable  -->
            <div class="col-12 mt-5 p-4 shadow rounded-4">
                <div class="mb-4 d-flex justify-content-between align-content-center">
                    <h2 class="fw-bolder">Documents</h2>
                    <button @click="clickModal()" class="btn btn-outline-primary">Add New Document</button>
                </div>
                <table id="dataTableAjax" ref="table" class="table table-hover table-striped fs-6 w-100">
                    <thead class="bg-light">
                        <tr>
                            <th style="50px;">#</th>
                            <th>Type</th>
                            <th>Photo</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </template>
    <Teleport to="body">
        <CreateDocumentView
            :documentTypes="documentTypes"
            @AddSuccess="AddSuccess()"
        />
        <EditDocumentViewVue
            :edata="edata"
            :documentTypes="documentTypes"
            @UpdateSuccess="UpdateSuccess()"
        />
        <EditBriefDescriptionView
            :brief="membership_info.about"
            @updateAbout="updateAbout($event)"
        />
        <EditLookingForView
            :looking="membership_info.looking_for"
            @updateLooking="updateLooking($event)"
        />
        <EditCompanyInfoView
            :company="membership_info"
            :countries="countries"
            @updateCompanyInfo="updateCompanyInfo($event)"
        />
        <EditContactPersonoView
            :company="membership_info"
            @updateContactPerson="updateContactPerson($event)"
        />
    </Teleport>
</template>

<script>
import axios from 'axios';
import { resource , domain } from '@/networks/domain';
import {getProfile , getDataTable , getDocumentById , saveDocument} from '@/networks/Admin/memberProfile.service'
import CreateDocumentView from '@/views/Admin/MemberShip/CreateDocumentView'
import EditDocumentViewVue from './EditDocumentView.vue';
import EditBriefDescriptionView from '@/views/Admin/MemberShip/EditBriefDescriptionView'
import EditLookingForView from '@/views/Admin/MemberShip/EditLookingForView'
import EditContactPersonoView from '@/views/Admin/MemberShip/EditContactPersonoView'
import EditCompanyInfoView from '@/views/Admin/MemberShip/EditCompanyInfoView'

export default {
    name : 'MemberShipView',
    components : {
        CreateDocumentView,
        EditDocumentViewVue,
        EditBriefDescriptionView,
        EditLookingForView,
        EditContactPersonoView,
        EditCompanyInfoView
    },
    data(){
        return {
            resource : resource,
            membership_info : {},
            isLoading : true,
            documentTypes : [],
            edata : {},
            countries : [],
        }
    },
    methods : {
        updateContactPerson(value){
            this.membership_info = value;
        },
        updateCompanyInfo(value){
            this.membership_info = value;
        },
        updateAbout(value){
            this.membership_info.about = value;
        },
        updateLooking(value){
            this.membership_info.looking_for = value;
        },
        edit(condition){
            $(`#${condition}`).toggleClass('d-block');
        },
        async editModal(id,idAttribute){
            $(`[button=edit${idAttribute}]`).prop("disabled", true);
            await axios.get(`${getDocumentById}?id=${id}`)
            .then((res)=>{
                if(res.data.data != ''){
                    this.edata = res.data.data;
                    $('#imgEdit').attr("src",`${resource + res.data.data.photo}`);
                    $('#edit').toggleClass('d-block');
                }
            })
            .catch((err)=>{
                console.log(err);
            })
            $(`[button=edit${idAttribute}]`).prop("disabled", false);
        },
        deleteModal(id){
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4154f1',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(saveDocument,{'id' : id})
                    .then((res)=>{
                        if(res.data.status == 200){
                            this.$swal(
                                {
                                    position: 'top-center',
                                    icon: 'success',
                                    title: res.data.sms,
                                    showConfirmButton: false,
                                    timer: 1200
                                }
                            );
                            $('#dataTableAjax').DataTable().ajax.reload();
                        } else {
                            this.$swal(
                                {
                                    icon: 'error',
                                    title : `${res.data.sms}`
                                }
                            );
                        }
                    })

                }
            })
        },
        clickModal(){
            // When the user clicks on the button, open the modal
            $('#create').toggleClass('d-block');
        },
        AddSuccess(){
            $('#dataTableAjax').DataTable().ajax.reload();
        },
        UpdateSuccess(){
            $('#dataTableAjax').DataTable().ajax.reload();
        },
        ajaxDataTable(){
            var member_id = this.$store.state.auth.member_id;
            var token = this.$store.state.auth.token;
            var config = {
                pageLength: 10,
                processing: true,
                serverSide: true,
                scrollX: true,
                responsive: true,
                'ajax': {
                    'url': `${domain}${getDataTable}?member_id=${member_id}`,
                    'type': 'GET',
                    'beforeSend': function (request) {
                        request.setRequestHeader("Authorization", token);
                    }
                },
                columns: [
                    {data: 'DT_RowIndex', name: 'id', searchable: false, orderable: false},
                    {data: 'type', name: 'member_document_types.name'},
                    {data: 'photo', name: 'member_documents.photo'},
                    {data: 'description', name: 'member_documents.description'},
                    {
                        data: 'action', 
                        name: 'action', 
                        orderable: false, 
                        searchable: false,
                        render: function (data, type, row) {
                            const btnEdit = `<button type="button" button="editbtn${row.id}E"  id="btn${row.id}E" ref="${row.id}" class="editButton btn btn-outline-success"><i class="bi bi-pen iedit" ref="${row.id}" id="btn${row.id}E"></i></button>` ;
                            const btnDelete = `<button type="button" id="btn${row.id}D"  ref="${row.id}" class="deleteButton btn btn-outline-danger"><i class="bi bi-trash itrash" ref="${row.id}" id="btn${row.id}D"></i></button>` ;
                            var btn = '';
                            if(row.type_id == 3){
                                btn = btnEdit;
                            } else {
                                btn = btnEdit + ' ' + btnDelete;
                            }
                            return btn;
                        },
                    },
                ],
            }
            $("#dataTableAjax").DataTable(config);
        },
        dataTable(){
            const self = this;
            self.ajaxDataTable();
            
        },
        async getData(){
            await axios.get(`${getProfile}?member_id=${this.$store.state.auth.member_id}`)
            .then((res)=>{
                if(res.data.status == 200){
                    this.$store.commit('isApproved', res.data.data.membership_info.is_approve);
                    this.membership_info = res.data.data.membership_info;
                    var countrs = res.data.data.countries;
                    countrs.forEach(element => {
                        this.countries.push({id : element.id, text : element.name_en})
                    });
                    var documentTypes = res.data.data.document_types;
                    documentTypes.forEach(element => {
                        this.documentTypes.push({id : element.id, text : element.name});
                    });
                }
            })
            .catch((err)=>{
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
                console.log(err);
            })
            this.isLoading = false;
        },
    },
    async created(){
        await this.getData().then(()=>{
            $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 20);
        }).then(()=>{
            this.dataTable();
        }).then(()=>{
            const self = this;
            $('tbody', self.$refs.table).on( 'click', '.editButton', function(e){
                const id = e.target.getAttribute('ref');
                const idAttribute = e.target.id;
                if(id){
                    self.editModal(id,idAttribute)
                }
            });
            $('tbody', this.$refs.table).on( 'click', '.deleteButton', function(e){
                const id = e.target.getAttribute('ref');
                if(id){
                    self.deleteModal(id)
                }
            });
        })
    },
    mounted(){

    },
    updated(){
    
    }
}
</script>

<style scoped>
.page-link{
    background-color: aqua !important;
}
</style>