<template>
  <div class="col-lg-8 entries">
    <div v-if="isFetching" class="container position-relative p-0 mb-5">
      <div class="carousel-inner animated-background position-relative rounded">
        <div class="carousel-item btn-divide-left active">
          <div class="carousel-caption d-none d-md-block"></div>
        </div>
      </div>
    </div>
    <div v-else class="entry rounded">
        <div class="mb-5">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="fw-bolder mb-2">Membership Information</h2>
                </div>
            </div>
            <template v-for="(data, index) in member_categories" :key="data.id">
                <p>{{ index + 1 }}.
                    <span class="fw-bolder text-muted"> {{ data.name }}</span> <span v-html="data.description"></span>
                </p>
                <!-- <div v-html="data.description" class="mb-4 fs-6 fw-lighter" style="text-indent: 20px"></div> -->
            </template>
        </div>
        <form
            @submit.prevent="submitRegister()"
        >
            <div class="mb-3">
                <label for="companyName" class="form-label fw-bold">Company's Name <span class="text-danger">*</span></label>
                <input v-model="companyName" type="text" class="form-control" id="companyName" required>
            </div>
            <div class="mb-3">
                <label for="address" class="form-label fw-bold">Address <span class="text-danger">*</span></label>
                <input v-model="address" type="text" class="form-control" id="address" required>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="tel" class="form-label fw-bold">Tel <span class="text-danger">*</span></label>
                        <input v-model="tel" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="tel" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="email" class="form-label fw-bold">Email <span class="text-danger">*</span></label>
                        <input v-model="email" type="email" class="form-control" id="email" required>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label for="Selectcountry" class="form-label fw-bold">Country <span class="text-danger">*</span></label>
                        <Select2 
                            id="Selectcountry"
                            v-model="country" 
                            :options="countries" 
                            @change="myChangeEvent($event)"
                            @select="mySelectEvent($event)"
                            required
                        />
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="cityState" class="form-label fw-bold">City / State <span class="text-danger">*</span></label>
                        <input v-model="city" type="text" class="form-control" id="cityState" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="zipCode" class="form-label fw-bold">Zip Code</label>
                        <input v-model="zip_code"  type="text" class="form-control" id="zipCode" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" >
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                
                <div class="col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label for="bzGroup" class="form-label fw-bold">Business Group <span class="text-danger">*</span></label>
                        <!-- <select v-model="bzGroup" id="bzGroup" class="form-control" required>
                            <option :value="data.id" v-for="data in business_groups" :key="data.id">{{ data.name }}</option>
                        </select> -->
                        <Select2 
                            id="bzGroup"
                            v-model="bzGroup" 
                            :options="business_groups" 
                            @change="myChangeEvent($event)"
                            @select="mySelectEvent($event)"
                            required
                        />
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="key" class="form-label fw-bold">Key Contact Person's Name <span class="text-danger">*</span></label>
                        <input v-model="keyContactPersonName" type="text" class="form-control" id="key" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="emailKey" class="form-label fw-bold">Key Contact Person's Email <span class="text-danger">*</span></label>
                        <input v-model="keyEmail" type="email" class="form-control" id="emailKey" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="position" class="form-label fw-bold">Key Contact Person's Position <span class="text-danger">*</span></label>
                        <input v-model="keyPosition" type="text" class="form-control" id="position" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="phone" class="form-label fw-bold">Key Contact Person's Phone <span class="text-danger">*</span></label>
                        <input v-model="keyPhone" type="text" class="form-control" id="phone" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="youtube" class="form-label fw-bold">Youtube</label>
                        <input v-model="youtube" type="text" class="form-control" id="youtube">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="linkedin" class="form-label fw-bold">Linkedin</label>
                        <input v-model="linkedin" type="text" class="form-control" id="linkedin">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="website" class="form-label fw-bold">Website</label>
                        <input v-model="website" type="text" class="form-control" id="website">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="instagram" class="form-label fw-bold">Instagram</label>
                        <input v-model="instragram" type="text" class="form-control" id="instagram">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="facebook" class="form-label fw-bold">Facebook</label>
                        <input v-model="facebook" type="text" class="form-control" id="facebook">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="twitter" class="form-label fw-bold">Twitter</label>
                        <input v-model="twitter" type="text" class="form-control" id="twitter">
                        <div class="form-text">This field is optional.</div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <label class="form-label fw-bold mb-3">Catergories of Members <span class="text-danger">*</span></label>
                    <div class="mb-3 form-check" v-for="data in member_categories" :key="data.id">
                        <input v-model="memberCategory" class="form-check-input" name="memberCategory" type="radio" :id="`check${data.id}box`" :value="data.id" :disabled="data.id == 1" required>
                        <label class="form-check-label" :for="`check${data.id}box`">
                           {{ data.name }}, Annual Fee {{ data.annual_fee }}{{ data.curency }} 
                           {{ data.is_hide_exchange == 0 ? '( ' + data.exchange + " " + data.exchange_currency + ' )' : '' }} 
                        </label>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="userEmail" class="form-label fw-bold">User Email <span class="text-danger">*</span></label>
                        <input v-model="userEmail" type="email" class="form-control" id="userEmail" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="userPassword" class="form-label fw-bold">User Password <span class="text-danger">*</span></label>
                        <input v-model="userPassword" type="password" class="form-control" id="userPassword" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="confirmEmail" class="form-label fw-bold">Confirm Email <span class="text-danger">*</span></label>
                        <input v-model="userConfirmEmail" type="email" class="form-control" id="confirmEmail" required>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="confirmPassword" class="form-label fw-bold">Confirm Password <span class="text-danger">*</span></label>
                        <input v-model="userConfirmPassword" type="password" class="form-control" id="confirmPassword" required>
                    </div>
                </div>
            </div>
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="checkTerm" required>
                <label class="form-check-label" for="checkTerm">Terms and Conditions <span class="text-danger">*</span></label>
            </div>
            <div class="mb-3">
                <p>I have read and agreed to CBC <router-link :to="{ name : 'term-condition', params :{ data : $CryptoJS.AES.encrypt(`${termandcondition.description_en}`, 'Secret Passphrase').toString() } }" class="text-info">{{ termandcondition.name_en }}</router-link></p>
            </div>
            <div class="mt-3 text-end">
                <button type="submit" :disabled="isLoading" class=" btn btn-outline-primary">
                    <span v-if="isLoading">Loading...</span>
                    <span v-else>Sumbit</span>
                </button>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="has-dark-gray-color has-text-color" style="color:#000000"><strong>Please note the requirement documents as following:</strong></p>
            <ul>
                <li>Current Certificate of&nbsp;Registration with Ministry of Commerce (Scanned Copy)</li>
                <li>Patent Tax Certificate (Scanned Copy)</li>
                <li>Name and contact of CEO and additional representative</li>
                <li>Company LOGO</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {getRegisterData, submitRegister} from '@/networks/Auth/register.service'
import Select2 from '@/components/select2/Select2View.vue';

export default {
    name : 'RegisterView',
    emits : ["select", "onSelect"],
    components: {Select2},
    data(){
        return{
            business_groups : [],
            member_categories : [],
            countries : [],
            termandcondition : '',

            country : '',
            zip_code : '',
            city : '',
            companyName : '',
            address: '',
            tel : '',
            youtube : '',
            email : '',
            linkedin : '',
            website : '',
            instragram : '',
            facebook : '',
            twitter : '',
            keyContactPersonName : '',
            keyEmail : '',
            keyPosition : '',
            keyPhone : '',
            bzGroup: '',
            memberCategory : '',
            userEmail : '',
            userPassword : '',
            userConfirmEmail : '',
            userConfirmPassword : '',
            isLoading : false,
            isFetching : true,
            membershipbenifits : {}
        }
    },
    methods : {
        myChangeEvent(val){
            // console.log(val);
        },
        mySelectEvent({id, text}){
            // console.log({id, text})
            // console.log(this.bzGroup);
        },
        async getData(){
            await axios.get(getRegisterData)
                .then((res)=>{
                    var bzGroups = res.data.data.business_groups;
                    bzGroups.forEach(element => {
                        this.business_groups.push({id : element.id, text : element.name});
                    });
                    var countries = res.data.data.countries;
                    countries.forEach(element => {
                        this.countries.push({id : element.id, text : element.name_en});
                    });
                    this.member_categories = res.data.data.member_categories;
                    this.termandcondition = res.data.data.termandcondition;
                    this.membershipbenifits = res.data.data.membershipbenifits;
                    this.isFetching = false;
                })
                .then(()=>{
                    $('html, body').animate({
                        scrollTop: $("#main").offset().top - 100
                    }, 20);
                })
                .catch((err)=>{
                    console.log(err);
                })
        },
        async submitRegister(){
            this.isLoading = true;
            var data = {
                'name' : this.companyName,
                'phone' : this.tel,
                'email' : this.email,
                'address' : this.address,
                'website' : this.website,
                'youtube' : this.youtube,
                'linkedin' : this.linkedin,
                'instagram' : this.instagram,
                'facebook' : this.facebook,
                'twitter' : this.twitter,
                'business_group_id' : this.bzGroup,
                'member_category_id' : this.memberCategory,
                'key_person_name' : this.keyContactPersonName,
                'key_person_email' : this.keyEmail,
                'key_person_phone' : this.keyPhone,
                'key_person_position' : this.keyPosition,
                'user_email' : this.userEmail,
                'user_email_confirmation' : this.userConfirmEmail,
                'user_password' : this.userPassword,
                'user_password_confirmation' : this.userConfirmPassword,
                'country_id' : this.country,
                'city' : this.city,
                'zip_code' : this.zip_code
            };

            axios.post(submitRegister, data)
            .then((res)=>{
                if(res.data.status == 200){
                    this.companyName = '';
                    this.tel = '';
                    this.email = '';
                    this.website = '';
                    this.youtube = '';
                    this.linkedin = '';
                    this.instagram = '';
                    this.facebook = '';
                    this.twitter = '';
                    this.bzGroup = '';
                    this.memberCategory = '';
                    this.keyContactPersonName = '';
                    this.keyEmail = '';
                    this.keyPhone = '';
                    this.keyPosition = '';
                    this.userEmail = '';
                    this.userConfirmEmail = '';
                    this.userPassword = '';
                    this.userConfirmPassword = '';
                    this.country = '';
                    this.zip_code = '';
                    this.city = '';
                    const self = this;
                    this.$swal(
                        {
                            icon: 'success',
                            title: "Registration Successfully",
                            text : res.data.sms
                        }
                    ).then((result) => {
                        if (result.isConfirmed) {
                            // self.$router.push('/login');
                            self.$router.push('/register-success');
                        }
                    });
                } else{
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
                this.isLoading = false;
            })
            .catch((err)=>{
                this.$swal(
                    {
                        icon: 'warning',
                        title: err.message,
                    }
                )
                // console.log(err);
                this.isLoading = false;
            });
        }
    },
    async created(){
        await this.getData();
    }
}
</script>

<style>

</style>