<template>
<div class="col-lg-8 entries">
    <div class="entry rounded">
        <div class="mb-5">
            <h2 class="fw-bolder mb-2">Terms and Conditions</h2>
            <div v-html='$CryptoJS.AES.decrypt(`${$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)'></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    mounted(){
        $('html, body').animate({
            scrollTop: $("#main").offset().top - 100
        }, 20);
    }
}
</script>

<style>

</style>