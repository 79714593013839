import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomePage'
import MainLayout from '@/components/inc/MainLayout'
import NewsLayout from '@/components/inc/NewsLayout'
import NewsView from '@/views/NewsPage'
import NewDetailView from '@/views/NewsPage/NewDetailView'
import RegisterView from '@/views/Auth/Register'
import LoginView from '@/views/Auth/Login/LoginView'
import TermConditionView from '@/views/Auth/Register/TermConditionView'
import ForgetPasswordView from '@/views/Auth/Login/ForgetPasswordView'
import AdminLayout from '@/components/inc/AdminLayout'
import CompanyView from '@/views/Admin/Company'
import DocumentView from '@/views/Admin/Document'
import MemberShipView from '@/views/Admin/MemberShip'
import CompanyDetailView from '@/views/Admin/Company/CompanyDetailView'
import CredentialView from '@/views/Auth/Credential'
import MemberBenefitView from '@/views/Auth/Register/MemberBenefitView'
import OrganizationChartView from '@/views/OrganizationStructureChart'
import BoardView from '@/views/BoardPage'
import RegisterSuccessView from '@/views/Auth/Register/RegisterSuccessView'
import ResetPasswordView from '@/views/Auth/Login/ResetPasswordView'
import ResetPasswordSuccessView from '@/views/Auth/Login/ResetPasswordSuccessView'
import EventView from '@/views/Event/index'
import EventDetailView from '@/views/Event/detail'
import ProjectView from '@/views/Project/index'
import ProjectDetailView from '@/views/Project/detail'
import PartnerView from '@/views/Partner/index'
import PartnerDetailView from '@/views/Partner/detail'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children : [
      {
        path: '',
        name: 'home',
        component: HomeView,
        props : true
      },
      {
        path: '/register-success',
        name: 'register-success',
        component: RegisterSuccessView,
        // props : true
      },
      // {
      //   path: '/reset-password-success',
      //   name: 'reset-password-success',
      //   component: ResetPasswordSuccessView,
      // },
      {
        path: '/news',
        component: NewsLayout,
        children : [
          {
            path: '',
            name: 'news',
            component: NewsView,
          },
          {
            path: '/news/detail/:id',
            name: 'news-detail',
            component: NewDetailView,
          },
          {
            path: '/register',
            name: 'register',
            component: RegisterView
          },
          {
            path : '/register/member-benefit/:data',
            name : 'membership-benefit',
            component :MemberBenefitView
          },
          {
            path: '/login',
            name: 'login',
            component: LoginView
          },
          {
            path:'/term-condition/:data',
            name:'term-condition',
            component : TermConditionView
          },
          {
            path: '/login/forget-password',
            name: 'forget-password',
            component : ForgetPasswordView
          },
          {
            path: '/verify-reset-password-link/:token',
            name: 'reset-password',
            component: ResetPasswordView,
          },
          {
            path : '/organization-structure-chart/:data',
            name : 'organization-structure-chart',
            component : OrganizationChartView
          },
          {
            path : '/board',
            name : 'board',
            component : BoardView
          },
          {
            path: '/event',
            name: 'event',
            component: EventView,
          },
          {
            path : '/event/:id',
            name : 'event-detail',
            component : EventDetailView
          },
          {
            path: '/project',
            name: 'project',
            component: ProjectView,
          },
          {
            path : '/project/:id',
            name : 'project-detail',
            component : ProjectDetailView
          },
          {
            path: '/partner',
            name: 'partner',
            component: PartnerView,
          },
          {
            path : '/partner/:id',
            name : 'partner-detail',
            component : PartnerDetailView
          }
        ]
      },
      {
        path: '/admin/company',
        component :AdminLayout,
        children : [
          {
            path : '',
            name : 'admin-company',
            component : CompanyView
          },
          {
            path : '/admin/document',
            name : 'admin-document',
            component : DocumentView
          },
          {
            path : '/admin/membership-profile',
            name : 'admin-membership-profile',
            component : MemberShipView
          },
          {
            path : '/admin/member/detail/:id',
            name : 'admin-member-detail',
            component : CompanyDetailView
          },
          {
            path : '/admin/credential',
            name : 'admin-credential',
            component : CredentialView
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect : '/'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path != '/'){
    if($('#dropNavBoard').hasClass("dropdown-active")){
      $('#dropNavBoard').toggleClass('dropdown-active');
    }
    if($('#dropNav').hasClass("dropdown-active")){
      $('#dropNav').toggleClass('dropdown-active');
    }
    // $('.dropdown').trigger('click');
    $('.bi-x').trigger('click');
  }
  next();
});

export default router
